import React, { useState } from 'react';
import { Text, Box } from 'react-limbix-ui';

import { useTranslation } from 'react-i18next';

import {
  WarningSVG,
  CancelButton,
  ConfirmButton,
} from '../Modal.styles';

import ExclamationSVG from '@/assets/ExclamationCircle.svg';

import { ModalPayloadType } from '@/types/ReduxTypes';
import { usePreventNavigation } from '@/hooks/redux';

type Props = {
  payload: ModalPayloadType['IDLE_WARNING'];
  onClose: () => void;
};
const IdleWarning: React.FC<Props> = (props: Props) => {
  const {
    onClose,
    payload,
  } = props;

  const {
    countdownDuration,
    stayActive,
    isLeader,
    pause,
    onLogout,
  } = payload;
  const [, setPreventNavigation] = usePreventNavigation();
  const [counter, setCounter] = useState(countdownDuration / 1000);
  const { t } = useTranslation();

  React.useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (counter > 0) {
      timeout = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (!isLeader()) {
      pause();
    } else {
      setPreventNavigation(false);
      onLogout();
    }
    return () => clearTimeout(timeout);
  }, [counter]);

  const closeAndStayActive = () => {
    onClose();
    stayActive();
  };

  const closeAndLogout = () => {
    onClose();
    onLogout();
  };

  return (
    <Box width="425px" padding="32px" position="relative">
      <Box display="inline-flex">
        <Box margin="0 6px">
          <WarningSVG src={ExclamationSVG} />
        </Box>
        <Box display="block">
          <Box marginBottom="8px">
            <Text fontWeight="700" fontSize="18px" lineHeight="26px">
              {t('timeout.expire')}
            </Text>
          </Box>
          <Box marginBottom="8px">
            <Text fontSize="16px" lineHeight="24px">
              {t('timeout.inactive_a_while')}
            </Text>
          </Box>
          <Box display="inline-flex">
            <Text fontWeight="700" fontSize="16px" lineHeight="26px">
              {t('timeout.logged_off_in')}
            </Text>
            <Box margin="0 5px">
              <Text fontWeight="700" fontSize="16px" lineHeight="26px" color="#DF5F30">
                {counter}
              </Text>
            </Box>
            <Text fontWeight="700" fontSize="16px" lineHeight="26px">
              {t('timeout.seconds')}
            </Text>
          </Box>
          <Box display="flex" marginTop="24px">
            <Box margin="0 0 0 auto" display="inline-flex">
              <CancelButton onClick={closeAndLogout}>
                {t('timeout.logout')}
              </CancelButton>
              <ConfirmButton onClick={closeAndStayActive}>
                {t('timeout.signed_in')}
              </ConfirmButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IdleWarning;
