import useActions from './useActions';

import { ShowModalActionType, HideModalActionType } from '@/store/actions/modalActions';

const useModal = (): [ShowModalActionType, HideModalActionType] => {
  const { modal: { showModal, hideModal } } = useActions();
  return [showModal, hideModal];
};

export default useModal;
