import React, { useCallback, useState } from 'react';
import {
  Link, Prompt, Redirect, RouteComponentProps, useParams,
} from 'react-router-dom';
import { GraphQLError } from 'graphql';
import {
  Container, Snackbar, Step, StepLabel, Stepper,
} from '@material-ui/core';
import {
  AssessmentForm, LoadingSpinner, UseParamsType, Text, Box,
} from 'react-limbix-ui';
import { Alert } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';

import { useMutation, useQuery } from '@/hooks/apollo';
import { logEvent } from '@/utils/analytics';

import {
  MutationReportParticipantAssessmentResultArgs,
  AssignmentTypeOfClient,
  AssessmentType,
  QuestionType,
  AssignmentType,
} from '@/apollo/types';
import {
  REPORT_PARTICIPANT_ASSESSMENT_RESULT_MUTATION,
} from '@/apollo/mutations';
import { ASSESSMENT_QUERY, ASSIGNMENTS_QUERY, ME_QUERY } from '@/apollo/queries';
import { GraphQLErrorsType } from '@/types';
import { useModal } from '@/hooks/redux';
import { useAssignmentMutations } from '@/hooks/apollo/mutations';

const Assessment: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { location } = props;
  const { t } = useTranslation();
  const [showModal] = useModal();
  const { assignmentUid } = useParams<UseParamsType>();
  const meQueryData = useQuery(ME_QUERY);
  const { startAssignment, completeAssignment } = useAssignmentMutations();
  const [reportParticipantAssessmentResult] = useMutation<MutationReportParticipantAssessmentResultArgs>(
    REPORT_PARTICIPANT_ASSESSMENT_RESULT_MUTATION,
  );
  const [mutationErrors, setMutationErrors] = useState<GraphQLErrorsType>(null);
  const assignmentQueryData = useQuery(
    ASSIGNMENTS_QUERY,
    {
      variables: {
        onlyVisible: true,
        clientType: AssignmentTypeOfClient.WebPortal,
      },
    },
  );

  const assessmentUid = (location?.state as { assessmentUid: string })?.assessmentUid;
  const { data: assessmentData, loading: assessmentQueryLoading, error } = useQuery(ASSESSMENT_QUERY,
    { variables: { assessmentUid } });

  const renderSkippedQuestionsModal = useCallback((
    skippedQuestions: QuestionType[],
    onSkip: () => void,
    onReturn?: () => void,
  ) => {
    const questionsMissingAnswers = skippedQuestions.map((question) => (
      <div key={question.uid}>
        <span>{question.text}</span>
        <span style={{ color: 'red' }}>{question.isOptional ? '' : ' *'}</span>
      </div>
    ));
    showModal(
      'GENERIC_WARNING',
      {
        headerText: t('AF.skip.refuse'),
        body: questionsMissingAnswers,
        primaryButtonText: t('AF.skip.return'),
        secondaryButtonText: t('AF.skip.submit'),
        onClickPrimaryButton: onReturn,
        onClickSecondaryButton: onSkip,
      },
    );
  }, [showModal]);

  const renderCompletionPage = useCallback(() => (
    <Box key={1}>
      <Box marginBottom="48px" alignContent="center">
        <Text as="h2">
          {t('assessment.thanks')}
        </Text>
      </Box>
      <Link to="/">
        {t('assessment.back')}
      </Link>
    </Box>
  ), []);

  const isLoading = meQueryData.loading || assignmentQueryData.loading || assessmentQueryLoading;

  if (!assessmentUid) {
    return <Redirect to="/" />;
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return (
      <Box>
        {`Error: ${error?.message}`}
      </Box>
    );
  }

  const currentAssignment = assignmentQueryData?.data?.assignments
    ?.find((assignment: AssignmentType) => assignment?.uid === assignmentUid);

  const currentAssignmentIsComplete = !!currentAssignment?.completeTime;
  const currentAssignmentCanSkipQuestions = currentAssignment?.canSkipQuestions;
  const pdtUid = currentAssignment?.pdt?.uid || '';

  const handleStart = async () => {
    const result = await startAssignment({
      variables: {
        assignmentUid,
        startTime: new Date(),
      },
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    }
  };

  const handleSubmit = async (assessmentAnswers: string[]) => {
    logEvent({
      category: 'Assignment Submission',
      action: 'Submit Assessment',
      label: assessmentData.assessment.uid,
    });
    const result = await reportParticipantAssessmentResult({
      variables: {
        pdtUid,
        assessmentUid: assessmentData.assessment.uid,
        assessmentAnswers,
        assignmentUid,
      },
    });
    if (result.errors && result.errors.length > 0) {
      setMutationErrors(result.errors);
    } else {
      // Complete the assignment
      const resultOfCompletion = await completeAssignment({
        refetchQueries: [{
          query: ASSIGNMENTS_QUERY,
          variables: {
            onlyVisible: true,
            clientType: AssignmentTypeOfClient.WebPortal,
          },
        }],
        variables: {
          assignmentUid,
          completeTime: new Date(),
        },
      });
      if (resultOfCompletion.errors && resultOfCompletion.errors.length > 0) {
        setMutationErrors(resultOfCompletion.errors);
      }
    }
    return result;
  };

  const renderAssessmentForm = (assessment: AssessmentType) => (
    <Container>
      {
        assessment
                    && (
                      <AssessmentForm
                        assessment={assessment}
                        onSubmit={handleSubmit}
                        onStart={handleStart}
                        cancelButtonText={t('AF.cancel')}
                        submitButtonText={t('AF.submit')}
                        canSkipQuestions={currentAssignmentCanSkipQuestions}
                        onSubmitWithSkippedQuestions={renderSkippedQuestionsModal}
                      />
                    )
      }
      <Snackbar
        open={!!mutationErrors}
        onClose={() => { setMutationErrors(null); }}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error">
          {mutationErrors && mutationErrors.map((e: GraphQLError) => e.message)}
        </Alert>
      </Snackbar>
    </Container>
  );

  const currentStep = currentAssignmentIsComplete ? 1 : 0;
  return (
    <Box alignContent="center">
      <Stepper activeStep={currentStep}>
        <Step>
          <StepLabel>
            {t('assessment.questions')}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            {t('assessment.complete')}
          </StepLabel>
        </Step>
      </Stepper>
      <Box marginTop="32px" marginLeft="32px" marginBottom="64px">
        {
          assessmentData.assessment && (currentAssignmentIsComplete
            ? renderCompletionPage()
            : renderAssessmentForm(assessmentData.assessment))
        }
      </Box>
      <Prompt
        when={!currentAssignmentIsComplete}
        message={t('assessment.leave')}
      />
    </Box>
  );
};

export default Assessment;
