import { createAction } from '@reduxjs/toolkit';

import { NavigationActionTypes } from '@/types/ReduxTypes';

const blockNavigation = createAction<{ message: string }>(NavigationActionTypes.blockNavigation);
const unblockNavigation = createAction(NavigationActionTypes.unblockNavigation);

export default {
  blockNavigation,
  unblockNavigation,
};
