import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { CssBaseline, StylesProvider, ThemeProvider } from '@material-ui/core';
import { LoadingSpinner, EnvironmentSwitcher } from 'react-limbix-ui';

import App from './App';
import history from './utils/history';
import './i18n';
import { client } from './apollo';
import theme from './theme';
import store from './store';
import './index.css';

import { Login, ResetPassword } from '@/pages';
import { initializeAnalytics } from '@/utils/analytics';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://2ef2b1df37e1499a8f1d971534713649@o58970.ingest.sentry.io/5275036',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_DEPLOY_ENV,
    release: `parents@${process.env.APP_VERSION}`,
  });
}

initializeAnalytics();

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <Suspense fallback={<LoadingSpinner />}>
            <ApolloProvider client={client}>
              <Router history={history}>
                <Switch>
                  <Route component={Login} path="/login" />
                  <Route component={ResetPassword} path="/reset-password" />
                  <Route component={App} />
                </Switch>
                <EnvironmentSwitcher />
              </Router>
            </ApolloProvider>
          </Suspense>
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
