import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useActions from './useActions';

import { RootState } from '@/store';
import { NavigationType } from '@/types';

type usePreventNavigationReturnType = [NavigationType, (preventNavigation: boolean, message?: string) => void];

const usePreventNavigation = (defaultState?: boolean): usePreventNavigationReturnType => {
  const { navigation: { blockNavigation, unblockNavigation } } = useActions();

  useEffect(() => {
    if (defaultState) {
      blockNavigation();
    }
  }, []);

  const navigation = useSelector((state: RootState) => (state.navigation));
  const preventNavigationSetter = (preventNavigation: boolean, message?: string) => {
    if (preventNavigation) {
      blockNavigation({ message });
    } else {
      unblockNavigation();
    }
  };
  return [navigation, preventNavigationSetter];
};

export default usePreventNavigation;
