import { useMemo } from 'react';
import { bindActionCreators, Dispatch, AnyAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import {
  ModalActions,
  NavigationActions,
} from '@/store/actions';

const actionsObject = {
  modal: ModalActions,
  navigation: NavigationActions,
};

type ActionsType = typeof actionsObject;

type ActionObjectType = typeof ModalActions | typeof NavigationActions;

const bindDispatchToAction = (actions: ActionObjectType, dispatch: Dispatch<AnyAction>) => (
  Object.assign(
    {},
    ...Object.entries(actions).map(([actionName, action]) => (
      { [actionName]: bindActionCreators(action, dispatch) }
    )),
  ) as ActionObjectType
);

const bindDispatchToActionType = (actionTypes: ActionsType, dispatch: Dispatch<AnyAction>): ActionsType => (
  Object.assign(
    {},
    ...Object.entries(actionTypes).map(([actionType, actions]) => (
      { [actionType]: bindDispatchToAction(actions, dispatch) }
    )),
  ) as ActionsType
);

const useActions = (deps: unknown[] = []): ActionsType => {
  const dispatch = useDispatch();
  return useMemo(() => (
    bindDispatchToActionType(actionsObject, dispatch)
  ),
  deps ? [dispatch, ...deps] : [dispatch]);
};

export default useActions;
