import { createAction } from '@reduxjs/toolkit';

import {
  ModalActionTypes,
} from '@/types/ReduxTypes';

const showModal = createAction(
  ModalActionTypes.ShowModal,
  <T, M>(modalType: T, payload: M) => ({
    payload: {
      modalType,
      payload,
    },
  }),
);

const hideModal = createAction(ModalActionTypes.HideModal);

export type ShowModalActionType = typeof showModal;
export type HideModalActionType = typeof hideModal;

export default {
  showModal,
  hideModal,
};
