import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';

import { Link, Box, Text } from 'react-limbix-ui';

import Styled from './ResetPassword.styles';

import history from '@/utils/history';
import { useMutation } from '@/hooks/apollo';
import { RESET_PASSWORD } from '@/apollo/mutations';
import ResetPasswordPageGraphic from '@/assets/ResetPasswordPageGraphic.svg';
import { MutationResetPasswordArgs } from '@/apollo/types';

const ResetPassword : React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>();
  const [successText, setSuccessText] = useState<string>('');
  const [resetPassword] = useMutation<MutationResetPasswordArgs>(
    RESET_PASSWORD,
  );
  const { t } = useTranslation();

  const sendPasswordReset = async () => {
    const result = await resetPassword({
      variables: {
        email,
      },
    });
    if (result?.errors && result?.errors?.length > 0) {
      setError(result.errors[0]?.message);
    } else if (result.data?.resetPassword && result.data?.resetPassword.success === false) {
      setError(t('reset_password.invalid_email'));
    } else if (result.data?.resetPassword?.success) {
      setSuccessText(t('reset_password.link_sent'));
      setTimeout(() => {
        history.push('/login');
      }, 3000);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !!email) {
      sendPasswordReset();
    }
  };

  return (
    <Styled.MainContainer>
      <Styled.BackgroundGraphicContainer>
        <Styled.BackgroundGraphic src={ResetPasswordPageGraphic} alt="reset-password-graphic" />
      </Styled.BackgroundGraphicContainer>
      <Styled.FormContainer>
        <Box marginBottom="12px">
          <Text textAlign="center" fontSize="24px" lineHeight="32px" fontWeight={700}>
            {t('reset_password.reset_password')}
          </Text>
        </Box>
        <Box marginBottom="32px">
          <Text textAlign="center" fontSize="14px" lineHeight="20px" fontWeight={400}>
            {t('reset_password.blurb')}
          </Text>
        </Box>
        <Styled.StyledForm noValidate>
          <TextField
            style={{ marginBottom: 32 }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t('login.email_address')}
            name="email"
            value={email}
            autoFocus
            onKeyDown={handleKeyDown}
            onChange={(event) => setEmail(event.target.value?.trim())}
          />
          <Link.RouterLink to="/login">
            {t('reset_password.return_to_login')}
          </Link.RouterLink>
          { successText ? (
            <Text color="green">{successText}</Text>
          ) : (
            <Text color="red">{error}</Text>
          )}

          <Styled.SubmitButton
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={sendPasswordReset}
            disabled={!email}
          >
            {t('reset_password.send_link')}
          </Styled.SubmitButton>
        </Styled.StyledForm>
      </Styled.FormContainer>
    </Styled.MainContainer>
  );
};

export default ResetPassword;
