import { gql } from '@apollo/client';

export const ASSESSMENT_QUERY = gql`
    query Assessment($assessmentUid: String!) {
        assessment(assessmentUid: $assessmentUid) {
        uid
        isLocked
        name
        contextText
        introText
        preQuestionText
        questions {
            uid
            text
            questionType
            ordinal
            isOptional
            isSensitive
            answers {
            uid
            text
            }
            conditions {
            dependsOnQuestion {
                uid
                questionType
                ordinal
            }
            dependsOnAnswer {
                uid
            }
            isNegated
            }
        }
        }
    }
  `;

export const ME_QUERY = gql`
    query {
        me {
            accountType
        }
    }
`;

export const ASSESSMENTS_QUERY = gql`
    query($pdtUid: String) {
        assessments(pdtUid: $pdtUid) {
            uid,
            name,
        }
    }
`;

export const ASSIGNMENTS_QUERY = gql`
query($pdtUid: String, $onlyVisible: Boolean!, $clientType: String!) {
    assignments(onlyVisible: $onlyVisible, clientType: $clientType, pdtUid: $pdtUid) {
        uid,
        pdt {
            uid,
            name
        },
        assessment {
            name,
            uid
        },
        dueDate,
        typeOfAssignment,
        completeTime,
        canSkipQuestions,
        aboutUser {
            firstName,
            language
        }
    }
}
`;
