import {
  NavigationType,
  NavigationActionTypes,
  NavigationAction,
} from '@/types/ReduxTypes';

export type NavigationStateType = NavigationType;

export const defaultNavigationState: NavigationStateType = {
  isBlocked: false,
  message: null,
};

const navigationState: NavigationStateType = { ...defaultNavigationState };

function NavigationReducer(state: NavigationStateType = navigationState,
  dispatched: NavigationAction): NavigationStateType {
  switch (dispatched.type) {
  case NavigationActionTypes.blockNavigation:
    return {
      ...state,
      isBlocked: true,
      message: dispatched.payload.message,
    };
  case NavigationActionTypes.unblockNavigation:
    return {
      ...state,
      isBlocked: false,
      message: null,
    };
  default:
    return state;
  }
}

export default NavigationReducer;
