import { gql } from '@apollo/client';

export const REQUEST_TOKEN_MUTATION = gql`
  mutation RequestToken($email: String!, $password: String!, $clientType: String!) {
    tokenAuth(email: $email, password: $password, clientType: $clientType) {
      token,
      refreshToken,
      user {
        uid,
        emailVerified,
        firstName,
        language
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token,
      refreshToken
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
      resetPassword(email: $email) {
          success
      }
  }
  `;

export const REPORT_PARTICIPANT_ASSESSMENT_RESULT_MUTATION = gql`
    mutation($pdtUid: String!, $assessmentUid: String!, $assessmentAnswers: [String]!, $assignmentUid: String!) {
        reportParticipantAssessmentResult(
            pdtUid: $pdtUid,
            assessmentUid: $assessmentUid,
            assessmentAnswers: $assessmentAnswers,
            assignmentUid: $assignmentUid) {
            participantAssessmentResult {
                uid
            }
        }
    }
`;
