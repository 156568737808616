import styled from 'styled-components/macro';
import { Button } from '@material-ui/core';
import { Box } from 'react-limbix-ui';

const MainContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: stretch;
  min-height: 100vh;
`;

const LeftColumn = styled(Box)`
  background-color: #1F2E81;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  flex: 1;
  @media (min-width: 1400px) {
    max-height: 100vh;
    overflow: hidden;
  }
`;

const RightColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding-bottom: 32px;
`;

const LeftColumnContentContainer = styled(Box)`
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  padding-right: 88px;
  padding-left: 88px;
  flex: 1;
  margin-top: 32px;
  margin-bottom: 32px;
`;

const RightColumnContentContainer = styled(Box)`
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  display: flex;
  max-width: 60%;
  min-width: 300px;
  margin: 32px auto;
  height: 100%;
`;

const LoginHeaderContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 16px;
  background-color: #1F2E81;
  font-weight: 700;
`;

const LimbixLogo = styled.img`
  margin: 32px 0px 32px 0px;
  width: 124px;
`;

const PrivacyPolicyLink = styled.a`
  margin-bottom: 4px;
  text-align: center;
  color: #5E6574;
  line-height: 22px;
`;

const BottomContainer = styled(Box)`
  justify-content: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

export default {
  SubmitButton,
  MainContainer,
  LeftColumn,
  RightColumn,
  LeftColumnContentContainer,
  LoginHeaderContainer,
  RightColumnContentContainer,
  LimbixLogo,
  PrivacyPolicyLink,
  BottomContainer,
};
