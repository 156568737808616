import React, { useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';

import IdleWarning from './IdleWarning';
import GenericWarning from './GenericWarning';

import { RootState } from '@/store';
import { ModalContentType } from '@/types';
import { ModalPayloadType } from '@/types/ReduxTypes';
import { useActions } from '@/hooks/redux';

type Props<ModalType extends ModalContentType> = {
  payload: ModalPayloadType[ModalType];
  onClose: () => void;
};

const modalTypeToModal: { [key in ModalContentType]: React.FC<Props<key>>} = {
  IDLE_WARNING: IdleWarning,
  GENERIC_WARNING: GenericWarning,
  NONE: () => <></>,
};

const Modal: React.FC = () => {
  const { visible, modalType, payload } = useSelector(
    (state: RootState) => ({
      visible: state.modal.visible,
      modalType: state.modal.modalType,
      payload: state.modal.payload,
    }),
  );
  const { modal: { hideModal } } = useActions();

  useEffect(() => () => {
    hideModal();
  }, []);

  if (!visible) {
    return null;
  }

  const renderModalContent = () => {
    const ModalToRender = modalTypeToModal[modalType] as React.FC<Props<typeof modalType>>;
    return <ModalToRender payload={payload} onClose={hideModal} />;
  };

  return (
    <Dialog
      onClose={hideModal}
      aria-labelledby="dialog"
      open={visible}
    >
      {renderModalContent()}
    </Dialog>
  );
};

export default Modal;
