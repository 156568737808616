import { IdleTimerPayloadType } from './DataTypes';

export enum GlobalActionTypes {
  ClearStore = 'CLEAR_STORE',
}

export type GlobalAction = {
  type: GlobalActionTypes;
}

/* Navigation Action/Reducer Types */
export type NavigationType = {
  isBlocked: boolean;
  message?: string;
};

export enum NavigationActionTypes {
  blockNavigation = 'BLOCK_NAVIGATION',
  unblockNavigation = 'UNBLOCK_NAVIGATION',
}

export type NavigationAction = {
  type: NavigationActionTypes;
  payload?: {
    message: string;
  };
}

export enum ModalActionTypes {
  ShowModal = 'SHOW_MODAL',
  HideModal = 'HIDE_MODAL',
}

export type ModalPayloadType = {
  NONE: null;
  IDLE_WARNING: IdleTimerPayloadType;
  GENERIC_WARNING: {
    primaryButtonText: string;
    secondaryButtonText: string;
    onClickPrimaryButton?: () => void;
    onClickSecondaryButton?: () => void;
    headerText: string;
    body: React.ReactNode;
  };
};

export type ModalContentType = keyof ModalPayloadType;

export type ShowModalAction<ModalContent extends ModalContentType> = { type: ModalActionTypes.ShowModal }
  & {
  payload: {
    modalType: ModalContent,
    payload: ModalPayloadType[ModalContent],
  }
};

export type ModalAction = {
  type: ModalActionTypes.HideModal;
} | ShowModalAction<ModalContentType>;
