import React, { useEffect } from 'react';
import { useLocation, Prompt } from 'react-router-dom';

import { Memo } from 'react-limbix-ui';

import { usePreventNavigation } from '@/hooks/redux';

const PreventNavigationPrompt: React.FC = () => {
  const { pathname } = useLocation();
  const [navigation, setPreventNavigation] = usePreventNavigation();

  useEffect(() => {
    setPreventNavigation(false);
  }, [pathname]);

  return (
    <Prompt
      when={navigation.isBlocked}
      message={navigation.message || 'You may have unsaved changes, are you sure you want to leave?'}
    />
  );
};

export default Memo(PreventNavigationPrompt);
